/* 新闻接口*/
import { axios } from '@/utils/http'

// 新闻列表
export const getNewsList = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded',
  payHeader: true
}, '/news/getList?' + params)

// 新闻详情
export const getNewsDetail = (params) => axios({
  method: 'GET',
  contentType: 'urlencoded',
  payHeader: true
}, '/news/getDetails?' + params)
