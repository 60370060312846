<template>
  <div class="pageMain" :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''">
    <!-- 搜索 -->
    <div class="banner_bj">
      <div class="fnt_42 txt">{{ globalDataArray.siteTemplateLanguage.search_result }}</div>
      <!-- 搜索 框 -->
      <div class="searchPageBox cf fnt_20">
        <div class="btn fr" @click="search">&nbsp;</div>
        <div class="ovh"><a-input v-model="listQuery.keyword" :placeholder="globalDataArray.siteTemplateLanguage.search_keywords" @keyup.enter="search" /></div>
      </div>
      <div></div>
    </div>
    <!-- 第二板块 -->
    <div class="search">
      <div class="search_mine w1200">
        <div class="search_left">
          <div class="buttons">
            <p class="fnt_18" @click="switchTab('1')" :class="curtype=='1' ? 'active' : '' ">{{ globalDataArray.siteTemplateLanguage.search_competition }}</p>
            <p class="fnt_18" @click="switchTab('2')" :class="curtype=='2' ? 'active' : '' ">{{ globalDataArray.siteTemplateLanguage.search_news }}</p>
          </div>
          <div class="searchKeyAndNum">
            <p class="fnt_20"><span class="onon set">{{ listQuery.keyword }} </span>{{ globalDataArray.siteTemplateLanguage.total }}<span class="onon allNum">{{ totalnum }}</span>{{ globalDataArray.siteTemplateLanguage.unit }}</p>
          </div>
        </div>

        <!-- 赛题 -->
        <div class="search_right" v-if="curtype=='1'">
          <div class="searchright_box" v-for="(item, index) in competition" :key="'competition'+index">
            <div class="mine">
              <div class="box_tit1 fnt_24">
                <router-link v-if="fromQuery!=''&&fromQuery!=null" target="_blank" class="item" :to="'/competitionInfo?id='+item.parentId+'&from='+fromQuery">
                  <div v-heightline="listQuery.keyword">{{ item.title }}</div>
                </router-link>

                <router-link v-else target="_blank" class="item" :to="'/competitionInfo?id='+item.parentId">
                  <div v-heightline="listQuery.keyword">{{ item.title }}</div>
                </router-link>
              </div>

              <div class="box_tit3 fnt_16" :title="item.introduction" v-heightline="listQuery.keyword">{{ item.introduction }}</div>
              <div class="box_tit4">
                <ul class="box_tit4ul">
                  <li v-if="item.bonus!=''&&item.bonus!=null">
                    <p class="fnt_22">{{ item.bonus }}</p>
                    <span class="fnt_14">{{ globalDataArray.siteTemplateLanguage.competition_bonus }}</span>
                  </li>
                  <li>
                    <p class="fnt_22" v-if="item.teams!=null&&item.teams!=''">{{ item.teams }}</p>
                    <p class="fnt_22" v-else>0</p>
                    <span class="fnt_14">{{ globalDataArray.siteTemplateLanguage.competition_teams }}</span>
                  </li>
                  <li v-if="item.type_dictText!=''&&item.type_dictText!=null">
                    <p class="fnt_22">{{ item.type_dictText }}</p>
                    <span class="fnt_14">{{ globalDataArray.siteTemplateLanguage.competition_type }}</span>
                  </li>
                  <li v-if="item.localEndtime!=''&&item.localEndtime!=null">
                    <p class="fnt_18">{{ item.localEndtime }}</p>
                    <span class="fnt_14">{{ globalDataArray.siteTemplateLanguage.competition_end_signup }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- 新闻 -->
        <div class="search_right" v-if="curtype=='2'">
          <div class="searchright_box" v-for="(item, index) in list" :key="'list'+index">
            <div class="mine">
              <div class="box_tit1 fnt_24">
                <router-link v-if="fromQuery!=''&&fromQuery!=null" target="_blank" class="item" :to="'/newsinfo?id='+item.parentId+'&from='+fromQuery">
                  <div v-heightline="listQuery.keyword">{{ item.title }}</div>
                </router-link>
                <router-link v-else target="_blank" class="item" :to="'/newsinfo?id='+item.parentId">
                  <div v-heightline="listQuery.keyword">{{ item.title }}</div>
                </router-link>
              </div>

              <div class="box_tit3 fnt_16" v-if="item.introduction" :title="item.introduction" v-heightline="listQuery.keyword">{{ item.introduction }}</div>
              <div class="box_tit4">
                <span class="fnt_16 c-68" v-if="item.publicTime!=''&&item.publicTime!=null">{{ item.publicTime }}</span>
                <span class="fnt_16 c-68" v-else>{{ item.updateTime }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="p_page" v-if="totalnum>0">
          <a-pagination v-model="curpage" :total="totalnum" :page-size="listQuery.pageSize" @change="turnPageFun" :hide-on-single-page="true" show-less-items />&emsp;&emsp;<span>{{ globalDataArray.siteTemplateLanguage.total }} {{ totalnum }} {{ globalDataArray.siteTemplateLanguage.unit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { stringToDateTime } from '@/utils/util'
import { getCompetitionList } from '@/api/saiti'
import { getNewsList } from '@/api/news/index'
export default {
  name: 'Search',
  data() {
    return {
      title: this.globalDataArray.siteInfo.title,
      description: this.globalDataArray.siteInfo.description,
      keywords: this.globalDataArray.siteInfo.keywords,

      listQuery: { 'keyword': '', page: 1, pageSize: 20 },
      curpage: 1,
      totalnum: 0,

      competition: [], // 赛题列表信息
      list: [], // 新闻列表信息

      curtype: '1', // 当前搜索的类型1为赛题，2为新闻

      fromQuery: '' // 公共参数from
    }
  },
  created() {

  },

  mounted() {
    this.init()

    if (this.$route.query.from) {
      this.fromQuery = this.$route.query.from
    }

    if (typeof document === 'object') {
      document.documentElement.scrollTop = 0
    }
  },
  methods: {
    init() {
      const that = this
      if (that.$route.query.keyword != '' && that.$route.query.keyword != undefined) {
        that.listQuery.keyword = that.$route.query.keyword
      }
      that.getCompetitionFun()
    },

    // 赛题
    getCompetitionFun() {
      const that = this
      that.competition = []
      const param = 's=' + that.listQuery.keyword + '&page=' + that.listQuery.page + '&pageSize=' + that.listQuery.pageSize
      getCompetitionList(param).then(res => {
        // console.log(res)
        if (res.success && res.result != '' && Object.keys(res.result).length > 0) {
          that.totalnum = res.result.total
          that.curpage = res.result.current
          that.competition = res.result.records
          for (var i in that.competition) {
            // that.competition[i].markArr = that.competition[i].mark.split(',');
            if (that.competition[i].mark) {
              const mark = that.competition[i].mark
              that.competition[i].mark = mark.split(',')
            }

            if (that.competition[i].organizersLogo) {
              const organizersLogo = that.competition[i].organizersLogo
              that.competition[i].organizersLogo = organizersLogo.split(',')
            }

            if (that.competition[i].organizers) {
              const organizers = that.competition[i].organizers
              that.competition[i].organizers = organizers.split(',')
            }

            // 将utc时间转为当地时间并作比较
            const endUtcTime = that.competition[i].endUtcTime
            const localEndtimeStr = new Date(endUtcTime).getTime()
            that.competition[i]['localEndtime'] = stringToDateTime(localEndtimeStr)
            that.competition[i]['localEndtimeStr'] = localEndtimeStr
          }
          // res.result.records.forEach((item,index)=>{
          //   //console.log(new Date(item.endUtcTime).getTime())
          // })

          // console.log(that.competition)
        }
      })
    },

    // 新闻
    getNewsListFun() {
      const that = this
      that.list = []
      const param = 's=' + that.listQuery.keyword + '&p=' + that.listQuery.page + '&pageSize=' + that.listQuery.pageSize
      getNewsList(param).then(res => {
        if (res.success && res.result != '') {
          if (res.result.pageList && res.result.pageList.total) {
            that.totalnum = res.result.pageList.total
            that.list = res.result.pageList.records
            that.curpage = res.result.pageList.current
          } else {
            that.totalnum = 0
            that.list = []
          }

          that.$nextTick(() => {
            if (this.$refs.imgDiv && this.$refs.imgDiv.length > 0) {
              that.imgwidhth = that.$refs.imgDiv[0].offsetWidth
            }
          })
        }
      })
    },

    // 翻页
    turnPageFun(e) {
      this.listQuery.page = e
      this.getCompetitionFun()
      // 回调翻页页码
      // console.log(e)
    },
    // 搜索
    search() {
      const that = this
      if (that.listQuery.keyword == '') {
        that.$message.error(that.globalDataArray.siteTemplateLanguage.search_keywords)
        return false
      }

      that.listQuery.page = 1
      if (that.curtype == '1') {
        that.getCompetitionFun()
      }

      if (that.curtype == '2') {
        that.getNewsListFun()
      }
    },
    // 切换新闻赛题
    switchTab(type) {
      const that = this
      that.curtype = type
      that.listQuery.page = 1

      if (type == '1') {
        that.getCompetitionFun()
      }

      if (type == '2') {
        that.getNewsListFun()
      }
    }
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: 'description', name: 'description', content: this.description },
        { vmid: 'keywords', name: 'keywords', content: this.keywords }
      ]
    }
  }
}
</script>
<style>
@import '@/assets/css/search_result.css'
</style>
